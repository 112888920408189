import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout/Layout'
import { Container, Row, Col } from 'react-bootstrap'

export default ({ data }) => {
  const post = data.markdownRemark

  const postStyle = {
    color: 'black',
    backgroundColor: 'white',
    fontSize: 'calc(1rem + 0.5vw)',
    justifyContent: 'center',
    borderRadius: '25px',
    textAlign: 'justify',

  }

  return (
    <Layout backgroundImage>
      <Container className="px-5 py-3 my-5 " style={postStyle}>
        <Row>
          <Col>
            <h1 className="text-center my-5" style={{ color: '#f0ad4e' }}>
              {post.frontmatter.title}
            </h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <div dangerouslySetInnerHTML={{ __html: post.html }} />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
    }
  }
`
